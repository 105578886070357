import { Component, OnInit, Input } from '@angular/core';
import { FabricFileIconService } from './fabric-file-icon.service';
import { ICON_SIZES, SVG_SUFFIX, REFRESH_STRING, FileTypeIconSize } from './fabric-file-icon-constants';
import { distinctUntilChanged, map, startWith, filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import startWithDefault from '@app/_operators/startWithDefault';

@Component({
  selector: 'ms-fabric-file-icon',
  templateUrl: './fabric-file-icon.component.html',
  styleUrls: ['./fabric-file-icon.component.scss']
})
export class FabricFileIconComponent implements OnInit {
  @Input()
  iconSize: FileTypeIconSize;
  @Input()
  iconSuffix: '.svg' | '.png';

  private _iconType: string;
  @Input()
  get iconType() {
    return this._iconType;
  }
  set iconType(val: string) {
    this._iconType = this.fabricIconService.getFileTypeIconNameFromExtension(val);
  }

  @Input()
  iconSizeSet: '1.5x' | '2x' | '3x' | '4x' | undefined;
  versionString: string;
  constructor(private fabricIconService: FabricFileIconService) {
    this.iconSize = 20;
    this.iconSuffix = '.svg';
    this.versionString = REFRESH_STRING;
  }
  get baseUrl() {
    return this.fabricIconService.baseUrl();
  }
  ngOnInit() {}
}
